import { getOffersCols } from '@Components/Offer/Table/Columns/OfferColumnsConsult'
import { useOffersFilter } from '@Modules/offers/application/queries'
import { useOffers, usePostAwardOffer } from '@Modules/offers/application/queries/OfferQueries'
import { Offer, OfferState, PagedOffer } from '@Modules/offers/domain/entities/offer'
import { OfferRepository } from '@Modules/offers/domain/repositories/OfferRepository'
import { DataTablePFSEvent } from 'primereact/datatable'
import { useEffect, useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import { ColData } from 'ufinet-web-components'
import { PagingData, useTranslator } from 'ufinet-web-functions'

interface HookOutput {
	validateOfferToBeAwarded: () => void
	isOfferLoading: boolean
	offersSelected: Offer[]
	isOfferAwarding: boolean
	onSelectionOfferChange: (offer: Offer[]) => void
	offersCols: ColData<Offer>[]
	offersData?: PagedOffer
	setTablePFSEvent: (event?: DataTablePFSEvent) => void
	paging: PagingData
}

interface HookInput {
	offerRepository: OfferRepository
}

export const useOfferTableConsult = ({ offerRepository }: HookInput): HookOutput => {
	const translate = useTranslator()
	const [tablePFSEvent, setTablePFSEvent] = useState<DataTablePFSEvent>()
	const [offersSelected, setOffersSelected] = useState<Offer[]>([])
	const defaultPaging: PagingData = useMemo(
		() => ({
			pageNumber: 0,
			pageSize: 10,
			totalElements: 0,
			totalPages: 0,
		}),
		[]
	)
	const onErrorRetrievingData = (errorId: string) => toast.error(translate(`ERROR_${errorId}`))

	const { data: filtersData = { clients: [], currencies: [], kams: [], states: [] } } = useOffersFilter(
		offerRepository,
		translate
	)
	const offersCols = useMemo(() => getOffersCols(filtersData), [filtersData])
	const {
		data: offersData,
		isLoading: isOfferLoading,
		refetch: fetchOffersData,
	} = useOffers(offerRepository, tablePFSEvent, translate, onErrorRetrievingData)

	const [paging, setPaging] = useState<PagingData>(defaultPaging)

	useEffect(() => {
		setPaging(offersData?.pagingData ?? defaultPaging)
	}, [offersData, defaultPaging])

	const { mutate: awardOffer, isLoading: isOfferAwarding } = usePostAwardOffer(
		offerRepository,
		offersSelected[0]?.id,
		() => {
			fetchOffersData()
			setOffersSelected([])
		},
		() => onErrorRetrievingData('AWARD_OFFER')
	)

	const validateOfferToBeAwarded = () => {
		if (offersSelected[0].isAwardInternalStatusOk === true) {
			toast.info(translate('OFFER_ALREADY_AWARDED_SUCCESS'))
		} else if (offersSelected[0].isAwardInternalStatusOk === false) {
			toast.warning(translate('OFFER_ALREADY_AWARDED_ERROR'))
		} else {
			toast.success(translate('OFFER_AWARDED.SUCCESS', { offerId: offersSelected[0].offerId }))
			awardOffer()
		}
	}

	const onSelectionOfferChange = (offer: Offer[]) => {
		if (offer.length === 0 || offer.length > 2) {
			setOffersSelected([])
			return
		}

		const currentOfferSelected = offer[offer.length - 1]

		if (offersSelected.length > 0 && currentOfferSelected.id !== offersSelected[0]?.id) {
			toast.warning(translate('OFFER_NOT_SUBMITTED_ERROR'))
			return
		}

		if (currentOfferSelected.offerStateId !== OfferState.SUBMITTED) {
			toast.warning(translate('OFFER_NOT_SUBMITTED_WARNING'))
			return
		}

		setOffersSelected([currentOfferSelected])
	}

	return {
		validateOfferToBeAwarded,
		isOfferLoading,
		offersSelected,
		isOfferAwarding,
		onSelectionOfferChange,
		offersCols,
		offersData,
		setTablePFSEvent,
		paging,
	}
}
