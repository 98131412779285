import {
	OfferDetailsResponse,
	OfferRentabilityMetricsResponse,
	OfferRentabilityResponse,
} from '@Modules/offers/application/dtos/OfferDetailsResponse'
import {
	OfferDetails,
	OfferRentabilityMetricsType,
	OfferRentabilityType,
} from '@Modules/offers/domain/entities/offerDetails'
import { TranslatorFunction } from 'ufinet-web-functions'

const offerRentabilityMetricsMapper = (
	metrics: OfferRentabilityMetricsResponse,
	translate: TranslatorFunction
): OfferRentabilityMetricsType => {
	return {
		van: metrics.van,
		tir: metrics.tir,
		payBack: metrics.payBack,
		viable: metrics.viable ? translate(`OFFER.DETAILS.RENTABILITY.VIABILITY.${metrics.viable}`) : null,
	}
}

const offerRentabilityMapper = (
	rentability: OfferRentabilityResponse,
	translate: TranslatorFunction
): OfferRentabilityType => {
	return {
		standardEngineering: rentability.standardEngineering
			? offerRentabilityMetricsMapper(rentability.standardEngineering, translate)
			: null,
		standardAqs: rentability.standardAqs ? offerRentabilityMetricsMapper(rentability.standardAqs, translate) : null,
		riskEngineering: rentability.riskEngineering
			? offerRentabilityMetricsMapper(rentability.riskEngineering, translate)
			: null,
		riskAqs: rentability.riskAqs ? offerRentabilityMetricsMapper(rentability.riskAqs, translate) : null,
	}
}

export const offerDetailsMapper = (offerDetails: OfferDetailsResponse, translate: TranslatorFunction): OfferDetails => {
	return {
		name: offerDetails.name,
		clientName: offerDetails.clientName,
		finalClientName: offerDetails.finalClientName,
		contactName: offerDetails.contactName,
		kamName: offerDetails.kamName,
		countryName: offerDetails.countryName,
		companyName: offerDetails.companyName,
		currencyName: offerDetails.currencyName,
		economicModelName: offerDetails.economicModelName
			? translate(`ECONOMIC_MODEL.${offerDetails.economicModelName}`)
			: null,
		funnelState: offerDetails.funnelStateName ? translate(`FUNNEL_STATE.${offerDetails.funnelStateName}`) : null,
		period: offerDetails.period,
		mrc: offerDetails.mrc,
		nrc: offerDetails.nrc,
		totalAmount: offerDetails.totalAmount,
		arcReal: offerDetails.arcReal,
		mrcReal: offerDetails.mrcReal,
		totalAmountReal: offerDetails.totalAmountReal,
		nrcDiscount: offerDetails.nrcDiscount,
		mrcDiscount: offerDetails.mrcDiscount,
		mrcDiscountUpdated: offerDetails.mrcDiscountUpdated,
		offerGeneratedBackLog: offerDetails.offerGeneratedBackLog,
		rentability: offerDetails.rentability ? offerRentabilityMapper(offerDetails.rentability, translate) : null,
		prefeasibilityTicket: offerDetails.prefeasibilityTicket,
		calculationStateIng: offerDetails.calculationStateIngName
			? translate(`CALCULATION_STATE.${offerDetails.calculationStateIngName}`)
			: null,
		calculationStateAqs: offerDetails.calculationStateAqsName
			? translate(`CALCULATION_STATE.${offerDetails.calculationStateAqsName}`)
			: null,
		createdAt: offerDetails.createdAt,
		presentationDate: offerDetails.presentationDate,
		awardedDate: offerDetails.awardedDate,
		acceptanceDate: offerDetails.acceptanceDate,
		prefeasiblityRequestDate: offerDetails.prefeasiblityRequestDate,
		prefeasiblityReceptionDate: offerDetails.prefeasiblityReceptionDate,
		rejectionDate: offerDetails.rejectionDate,
		notViableDate: offerDetails.notViableDate,
		effectiveDateFrom: offerDetails.effectiveDateFrom,
		effectiveDateTo: offerDetails.effectiveDateTo,
		updatedAt: offerDetails.updatedAt,
		closureEstimatedDate: offerDetails.closureEstimatedDate,
		costs: offerDetails.costs,
	}
}
