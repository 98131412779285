import { OfferDetailsAmounts } from '@Components/OfferDetails/OfferDetailsAmounts'
import { OfferDetailsCosts } from '@Components/OfferDetails/OfferDetailsCosts'
import { OfferDetailsDates } from '@Components/OfferDetails/OfferDetailsDates'
import { OfferDetailsGeneralInformation } from '@Components/OfferDetails/OfferDetailsGeneralInformation'
import { OfferDetailsPrefeasibility } from '@Components/OfferDetails/OfferDetailsPrefeasibility'
import { OfferDetailsProducts } from '@Components/OfferDetails/OfferDetailsProducts'
import { OfferDetailsRentability } from '@Components/OfferDetails/OfferDetailsRentability'
import { OfferProductTableHOC } from '@Components/OfferProduct/Table/OfferProductTable'
import { useOfferDetails } from '@Modules/offers/application/queries'
import { OfferDetails } from '@Modules/offers/domain/entities/offerDetails'
import { HttpOfferRepository } from '@Modules/offers/infrastructure/repository/HttpOfferRepository'
import { useContext, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { Loading } from 'ufinet-web-components'
import { AuthContext, useTranslator } from 'ufinet-web-functions'

interface OfferDetailsPageProps {
	offer: OfferDetails
	translate: (key: string) => string
	productsTableComponent: JSX.Element
}

interface OfferNotFoundPageProps {
	translate: (key: string) => string
}

const OfferNotFoundPage = ({ translate }: OfferNotFoundPageProps) => {
	return <div>{translate('OFFER.DETAILS.NOT_FOUND')}</div>
}

export const OfferDetailsPage = ({ offer, translate, productsTableComponent }: OfferDetailsPageProps) => {
	return (
		<div>
			<div className="row gy-5">
				<div className="col-md-4" data-testid="OfferDetailsGeneralInformation">
					<OfferDetailsGeneralInformation
						name={offer.name}
						countryName={offer.countryName}
						clientName={offer.clientName}
						companyName={offer.companyName}
						finalClientName={offer.finalClientName}
						currencyName={offer.currencyName}
						contactName={offer.contactName}
						funnelState={offer.funnelState}
						economicModelName={offer.economicModelName}
						period={offer.period}
						kamName={offer.kamName}
						translate={translate}
					/>
				</div>
				<div className="col-md-4" data-testid="OfferDetailsAmounts">
					<OfferDetailsAmounts
						nrc={offer.nrc}
						mrc={offer.mrc}
						mrcReal={offer.mrcReal}
						nrcDiscount={offer.nrcDiscount}
						mrcDiscount={offer.mrcDiscount}
						mrcDiscountUpdated={offer.mrcDiscountUpdated}
						arcReal={offer.arcReal}
						offerGeneratedBackLog={offer.offerGeneratedBackLog}
						totalAmount={offer.totalAmount}
						totalAmountReal={offer.totalAmountReal}
						translate={translate}
					/>
				</div>
				<div className="col-md-4" data-testid="OfferDetailsDates">
					<OfferDetailsDates
						createdAt={offer.createdAt}
						presentationDate={offer.presentationDate}
						awardedDate={offer.awardedDate}
						acceptanceDate={offer.acceptanceDate}
						prefeasiblityRequestDate={offer.prefeasiblityRequestDate}
						prefeasiblityReceptionDate={offer.prefeasiblityReceptionDate}
						rejectionDate={offer.rejectionDate}
						notViableDate={offer.notViableDate}
						effectiveDateFrom={offer.effectiveDateFrom}
						effectiveDateTo={offer.effectiveDateTo}
						updatedAt={offer.updatedAt}
						closureEstimatedDate={offer.closureEstimatedDate}
						translate={translate}
					/>
				</div>
				<div className="col-md-4" data-testid="OfferDetailsRentabilityStandardAqs">
					<OfferDetailsRentability
						title={translate('OFFER.DETAILS.RENTABILITY.TITLE_STANDARD_AQS')}
						van={offer.rentability?.standardAqs?.van ?? null}
						payBack={offer.rentability?.standardAqs?.payBack ?? null}
						tir={offer.rentability?.standardAqs?.tir ?? null}
						viable={offer.rentability?.standardAqs?.viable ?? null}
						translate={translate}
					/>
				</div>
				<div className="col-md-4" data-testid="OfferDetailsRentabilityStandardIng">
					<OfferDetailsRentability
						title={translate('OFFER.DETAILS.RENTABILITY.TITLE_STANDARD_ING')}
						van={offer.rentability?.standardEngineering?.van ?? null}
						payBack={offer.rentability?.standardEngineering?.payBack ?? null}
						tir={offer.rentability?.standardEngineering?.tir ?? null}
						viable={offer.rentability?.standardEngineering?.viable ?? null}
						translate={translate}
					/>
				</div>
				<div className="col-md-4" data-testid="OfferDetailsRentabilityRiskAqs">
					<OfferDetailsRentability
						title={translate('OFFER.DETAILS.RENTABILITY.TITLE_RISK_AQS')}
						van={offer.rentability?.riskAqs?.van ?? null}
						payBack={offer.rentability?.riskAqs?.payBack ?? null}
						tir={offer.rentability?.riskAqs?.tir ?? null}
						viable={offer.rentability?.riskAqs?.viable ?? null}
						translate={translate}
					/>
				</div>
				<div className="col-md-4" data-testid="OfferDetailsRentabilityRiskIng">
					<OfferDetailsRentability
						title={translate('OFFER.DETAILS.RENTABILITY.TITLE_RISK_ING')}
						van={offer.rentability?.riskEngineering?.van ?? null}
						payBack={offer.rentability?.riskEngineering?.payBack ?? null}
						tir={offer.rentability?.riskEngineering?.tir ?? null}
						viable={offer.rentability?.riskEngineering?.viable ?? null}
						translate={translate}
					/>
				</div>
				<div className="col-md-4" data-testid="OfferDetailsPrefeasibility">
					<OfferDetailsPrefeasibility
						prefeasibilityTicket={offer.prefeasibilityTicket}
						calculationStateIng={offer.calculationStateIng}
						calculationStateAqs={offer.calculationStateAqs}
						translate={translate}
					/>
				</div>
				<div className="col-md-4" data-testid="OfferDetailsCostsOriginIng">
					<OfferDetailsCosts
						title={translate('OFFER.DETAILS.COSTS.TITLE_ORIGIN_ING')}
						puntualCostFo={offer.costs?.originCostEngineering?.puntualCostFo ?? null}
						puntualCostTeams={offer.costs?.originCostEngineering?.puntualCostTeams ?? null}
						puntualCostCivilWork={offer.costs?.originCostEngineering?.puntualCostCivilWork ?? null}
						puntualCostInfrastructure={offer.costs?.originCostEngineering?.puntualCostInfrastructure ?? null}
						totalCostMO={offer.costs?.originCostEngineering?.totalCostMO ?? null}
						translate={translate}
					/>
				</div>
				<div className="col-md-4" data-testid="OfferDetailsCostsOriginAqs">
					<OfferDetailsCosts
						title={translate('OFFER.DETAILS.COSTS.TITLE_ORIGIN_AQS')}
						puntualCostFo={offer.costs?.originCostAqs?.puntualCostFo ?? null}
						puntualCostTeams={offer.costs?.originCostAqs?.puntualCostTeams ?? null}
						puntualCostCivilWork={offer.costs?.originCostAqs?.puntualCostCivilWork ?? null}
						puntualCostInfrastructure={offer.costs?.originCostAqs?.puntualCostInfrastructure ?? null}
						totalCostMO={offer.costs?.originCostAqs?.totalCostMO ?? null}
						translate={translate}
					/>
				</div>
				<div className="col-md-4" data-testid="OfferDetailsCostsDestinationIng">
					<OfferDetailsCosts
						title={translate('OFFER.DETAILS.COSTS.TITLE_DESTINATION_ING')}
						puntualCostFo={offer.costs?.destinationCostEngineering?.puntualCostFo ?? null}
						puntualCostTeams={offer.costs?.destinationCostEngineering?.puntualCostTeams ?? null}
						puntualCostCivilWork={offer.costs?.destinationCostEngineering?.puntualCostCivilWork ?? null}
						puntualCostInfrastructure={offer.costs?.destinationCostEngineering?.puntualCostInfrastructure ?? null}
						totalCostMO={offer.costs?.destinationCostEngineering?.totalCostMO ?? null}
						translate={translate}
					/>
				</div>
				<div className="col-md-4" data-testid="OfferDetailsCostsDestinationAqs">
					<OfferDetailsCosts
						title={translate('OFFER.DETAILS.COSTS.TITLE_DESTINATION_AQS')}
						puntualCostFo={offer.costs?.destinationCostAqs?.puntualCostFo ?? null}
						puntualCostTeams={offer.costs?.destinationCostAqs?.puntualCostTeams ?? null}
						puntualCostCivilWork={offer.costs?.destinationCostAqs?.puntualCostCivilWork ?? null}
						puntualCostInfrastructure={offer.costs?.destinationCostAqs?.puntualCostInfrastructure ?? null}
						totalCostMO={offer.costs?.destinationCostAqs?.totalCostMO ?? null}
						translate={translate}
					/>
				</div>
			</div>

			<div className="row mt-5" data-testid="OfferDetailsProducts">
				<OfferDetailsProducts productsTableComponent={productsTableComponent} translate={translate} />
			</div>
		</div>
	)
}

export const OfferDetailsPageHOC = () => {
	const authData = useContext(AuthContext)
	const translate = useTranslator()
	const { offerId } = useParams()
	const offersRepository = useMemo(() => HttpOfferRepository(authData), [authData])
	const { data: offer, isLoading: offerIsLoading } = useOfferDetails(
		offersRepository,
		offerId ?? '',
		translate,
		() => {},
		Boolean(offerId)
	)

	if (offerId && offerIsLoading) {
		return (
			<div data-testid="OfferDetailsLoading">
				<Loading />
			</div>
		)
	}

	if (!offerId || !offer) {
		return (
			<div data-testid="OfferDetailsNotFound">
				<OfferNotFoundPage translate={translate} />
			</div>
		)
	}
	return (
		<OfferDetailsPage
			offer={offer}
			translate={translate}
			productsTableComponent={<OfferProductTableHOC offerRepository={offersRepository} offerId={offerId} />}
		/>
	)
}
