import {
	OfferProductResponse,
	PagedOfferProductResponse,
} from '@Modules/offers/application/dtos/PagedOfferProductResponse'
import { OfferProduct, PagedOfferProduct } from '@Modules/offers/domain/entities/offerProduct'
import { TranslatorFunction } from 'ufinet-web-functions'

const offerProductSerViceTypeNameAdapter = (
	serviceTypeName: string | undefined,
	translate: TranslatorFunction
): string => {
	switch (serviceTypeName) {
		case 'ETHERNET':
			return translate('SERVICE.TECHNOLOGY.TYPE.ETHERNET')
		default:
			return serviceTypeName ?? ''
	}
}

const offerNameHandler = (offerProduct: OfferProductResponse, translate: TranslatorFunction): string => {
	let offerName = `${translate(`SERVICE.TECHNOLOGY.${offerProduct.serviceTypeName}`)} `

	switch (offerProduct.serviceTypeName) {
		case 'CAPACIDAD_ID':
			offerName += `${offerProductSerViceTypeNameAdapter(offerProduct.technologyCapacityName, translate)} ${
				offerProduct.bandwidth
			} ${offerProduct.unitsName}`
			break

		case 'INTERNET_ID':
			offerName += `${offerProduct.accessTypeInternetName} ${offerProduct.bandwidth} ${offerProduct.unitsName}`
			break

		case 'TOWERING_ID':
			offerName += `${translate(`SERVICE.TOWERING.STRUCTURE_TYPES.${offerProduct.toweringStructureTypeName}`)} ${
				offerProduct.structureHeight
			}m`
			break

		case 'COLOCATION_ID':
			offerName += [
				translate(`SERVICE.COLLOCATION.EMPLACEMENT_TYPES.${offerProduct.emplacementTypeName}`),
				offerProduct.collocationQuantity,
				translate(`SERVICE.COLLOCATION.COLLOCATION_UNITS.${offerProduct.collocationUnitName}`),
			].join(' ')
			break

		case 'FIBRA_OSCURA_ID': {
			const fiberNumberSuffix = offerProduct.fiberNumber === 1 ? '' : 'S'
			const fiberNumberTranslation = translate(`SERVICE.DARK_FIBER.THREAD${fiberNumberSuffix}`)
			offerName += offerProduct.fiberNumber + ` ${fiberNumberTranslation}`
			break
		}

		default:
			break
	}

	return offerName
}

export const offerProductsPagedMapper = (
	pagedOfferProductsResponse: PagedOfferProductResponse,
	translate: TranslatorFunction
): PagedOfferProduct => {
	return {
		pagingData: pagedOfferProductsResponse.pagingData,
		offerProducts: pagedOfferProductsResponse.offerProducts.map((offerProduct: OfferProductResponse): OfferProduct => {
			return {
				...offerProduct,
				name: offerNameHandler(offerProduct, translate),
			}
		}),
	}
}
