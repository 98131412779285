import { useOfferTableConsult } from '@Components/Offer/Table/hooks/useOfferTableConsult'
import { emptyFunction } from '@Modules/common/utils/emptyFunction'
import { OfferRepository } from '@Modules/offers/domain/repositories/OfferRepository'
import { Table, UfinetButton } from 'ufinet-web-components'
import { useInternalUser, useTranslator } from 'ufinet-web-functions'

interface OfferTableConsultProps {
	offerRepository: OfferRepository
}

export const OfferTableConsult = ({ offerRepository }: OfferTableConsultProps) => {
	const translate = useTranslator()
	const internalUser = useInternalUser()

	const {
		isOfferLoading,
		offersSelected,
		onSelectionOfferChange,
		offersCols,
		offersData,
		validateOfferToBeAwarded,
		isOfferAwarding,
		setTablePFSEvent,
		paging,
	} = useOfferTableConsult({ offerRepository })

	return (
		<>
			<Table
				headerButtons={
					<>
						{internalUser && (
							<UfinetButton
								content={translate('OFFER.AWARD')}
								className="me-3"
								onClick={() => validateOfferToBeAwarded()}
								isDisabled={isOfferLoading || !offersSelected.length || isOfferAwarding}
							/>
						)}
					</>
				}
				selectedValues={offersSelected}
				onSelectionChange={onSelectionOfferChange}
				dataKey="offerId"
				className="fs-4 service-table"
				cols={offersCols}
				content={offersData?.offers}
				afterTablePFSEvent={setTablePFSEvent}
				lazySettings={
					paging && {
						...paging,
						loading: isOfferLoading,
						onPage: emptyFunction,
						onFilter: emptyFunction,
						onSort: emptyFunction,
					}
				}
				emptyMessage={isOfferLoading ? translate('LOADING_DOTS') : undefined}
				onFilterClear={() => setTablePFSEvent(undefined)}
			/>
		</>
	)
}
