import { OfferProduct } from '@Modules/offers/domain/entities/offerProduct'
import { ColData } from 'ufinet-web-components'
import { formatCellNumber, noContentPlaceholder, tableDataFormattingSettings } from 'ufinet-web-functions'

export const getOfferProductCols = (isInternalUser: boolean): ColData<OfferProduct>[] => [
	{
		field: 'name',
		header: 'OFFERS.CONSULT.HEADER.NAME',
		width: '20%',
		nonSortable: true,
		nonFilterable: true,
	},
	{
		field: 'period',
		header: 'OFFERS.CONSULT.HEADER.PERIOD',
		width: '10%',
		nonSortable: true,
		nonFilterable: true,
	},
	{
		field: 'mrc',
		header: isInternalUser ? 'OFFER.MRC' : 'OFFER.MRC.EXTERNAL_USER',
		width: '10%',
		body: (rowData: OfferProduct) =>
			rowData.mrc ? formatCellNumber(rowData.mrc, tableDataFormattingSettings) : noContentPlaceholder,
		nonSortable: true,
		nonFilterable: true,
	},
	{
		field: 'nrc',
		header: isInternalUser ? 'OFFER.NRC' : 'OFFER.NRC.EXTERNAL_USER',
		body: (rowData: OfferProduct) =>
			rowData.nrc ? formatCellNumber(rowData.nrc, tableDataFormattingSettings) : noContentPlaceholder,
		width: '10%',
		nonSortable: true,
		nonFilterable: true,
	},
	{
		field: 'generatedBacklog',
		header: 'OFFERS.CONSULT.HEADER.BACKLOG',
		body: (rowData: OfferProduct) =>
			rowData.generatedBacklog
				? formatCellNumber(rowData.generatedBacklog, tableDataFormattingSettings)
				: noContentPlaceholder,
		width: '10%',
		nonSortable: true,
		nonFilterable: true,
		hidden: !isInternalUser,
	},
	{
		field: 'origin',
		header: 'OFFERS.CONSULT.HEADER.ORIGIN',
		width: '20%',
		nonSortable: true,
		nonFilterable: true,
	},
	{
		field: 'destination',
		header: 'OFFERS.CONSULT.HEADER.DESTINATION',
		width: '20%',
		nonSortable: true,
		nonFilterable: true,
	},
]
